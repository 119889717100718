import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Grid, MenuList } from '@material-ui/core';
import ApplicationModel from './Components/ApplicationModel';

const MarkerComponent = ({ text, onClick }) => <div onClick={onClick} className="marker"><span className="marker-text">{text}</span></div>;

export default class LocationsComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = { locations: [], currentLocation: null };
        this.onChildClickCallback = this.onChildClickCallback.bind(this);

    }

    static defaultProps = {
        center: {
            lat: 51.6,
            lng: 4.9
        },
        zoom: 8
    };

    componentDidMount() {
        window.scrollTo(0, 1);
        fetch(
            ApplicationModel.APIBASE + '/GetCSLocations.php', {
            method: 'GET'
        }).then(response => response.json()).
            then(result => this.setState({ locations: result.locations }));

    }

    onChildClickCallback(key) {
        let place = this.state.locations.find(loc => loc.locationId === key);
        alert(place.description);
    }
    render() {
        let center = this.state.currentLocation ? { lat: this.state.currentLocation.latitude, lng: this.state.currentLocation.longitude }
            : { lat: 51.6, lng: 4.9 };
        let zoom = this.state.currentLocation ? 11 : 5;
        return (
            <div className="page-content" >
                <h2>Scoutsurfing</h2>
                <h3>What is it?</h3>
                <p><br/>Many Scouts in CES enjoy traveling around Europe, meeting others, visiting new places, making new friends or broadening their horizons. That is very nice, but travel costs can create a barrier. What a pity! In order to partially remove this barrier, we started ‘scoutsurfing’; a platform that enables scouts affiliated to CES to find a free night's stay in Europe and offer a place for the night; ‘their couch’. By connecting supply and demand on this platform, we can help each other.</p>

                <h3>How does it work?</h3>
                <p>The map below shows locations of CES affiliated scouts who basically offer a free place for the night. These are the hosts. For privacy reasons, only the places of residence of the hosts are shown and no addresses are listed on the map. If you, as a guest, are interested in an overnight stay at a location, you can make your wish known via the contact page of this website. The administrator will then contact the host to check if this place is available. If the response is positive, the host and the guest will be put in touch with each other. </p>
                <p>Do you wish to register as a host? Please let our administrator know.</p>

                <div style={{ display: "grid", gridTemplateColumns: "70% 30%", gridColumnGap: "8px" }} >

                    <div style={{ height: '70vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: ApplicationModel.MAPSKEY }}
                            defaultCenter={center}
                            defaultZoom={zoom}
                            center={center}
                            zoom={zoom}
                        >
                            {this.state.locations.map((loc) => <MarkerComponent key={loc.locationId} text={loc.description} lat={loc.latitude} lng={loc.longitude} />)}

                        </GoogleMapReact>
                    </div>
                    <div>
                        <ul style={{ listStyle: "none" }}>
                            {this.state.locations.map(loc =>
                                <li style={{ cursor: "pointer", padding: "4px", fontSize: "16px" }} key={loc.locationId} onClick={() => this.setState({ currentLocation: loc })}>
                                    {loc.country}:{loc.description}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <h2>FAQ</h2>
                <p>
                    <b>Q: Who is this platform for?</b><br/>
                    A: For anyone associated with CES. It will be verified by the administrator.
                </p>
                <p>
                    <b>Q: Can anyone associated with CES offer an overnight stay?</b><br/>
                    A: Yes please; the more the better! But please bear in mind that you, as a host, are responsible for compliance with local laws and regulations.
                </p>
                <p>
                    <b>Q: If I register as host, do I always have to be available?</b><br/>
                    A: No. When there is a request, the administrator will always ask you whether you are available and you always have the right to reject a request. If you are not available, the applicant will be told this without knowing who you are.
                </p>
                <p>
                    <b>Q: Can I ask for money for an overnight stay?</b><br/>
                    A: No, that is not the purpose of this platform.
                </p>
                <p>
                    <b>Q: Is CES responsible for guest or host behavior?</b><br/>
                    A: No. CES is not responsible for this. CES checks to which organization a guest belongs and knows to which organization a host belongs. We are all responsible scouts within a European fraternity, so we assume that visits will go well.
                </p>
            </div>

        );
    }
};