import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import ApplicationModel from './Components/ApplicationModel';

export default class AboutComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            htmlContent: null,
            currentSubMenuItem: "whatis",
            subMenuItems: [
                { id: "whatis", description: "What is CES?" },
                { id: "bureau", description: "Bureau" },
                { id: "history", description: "History" }
            ]
        };
        this.selectItem = this.selectItem.bind(this);
        this.fetchHTML = this.fetchHTML.bind(this);
    }

    fetchHTML(itemId) {
        fetch(ApplicationModel.HTMLBASE + 'about_content_' + itemId + '.html').then(response => response.text())
            .then(result => {

                this.setState({ htmlContent: result })
            });
    }

    componentDidMount() {
        this.fetchHTML(this.state.currentSubMenuItem);
    }




    selectItem(id) {
        this.setState({ currentSubMenuItem: id });
        this.fetchHTML(id);
    }

    render() {
        let gridTemplateColumns = "";
        for (let mn of this.state.subMenuItems) {
            gridTemplateColumns += "auto ";
        }
        return (
            <React.Fragment>
                <div className="subMenu" style={{ gridTemplateColumns: gridTemplateColumns }}>
                    {this.state.subMenuItems.map(mni =>
                        <a className={"subMenuItem" + (this.state.currentSubMenuItem === mni.id ? " selected" : "")} onClick={() => this.selectItem(mni.id)}>{mni.description}</a>
                    )}
                </div>
                <div className="page-content with-submenu" dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}>

                </div>
            </React.Fragment>
        );
    }
};