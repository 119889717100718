import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './images/logo128.png';
import LogoSmall from './images/logo64.png';

const MenuItems = [{ linkName: "/", description: "Home" },

{ linkName: "/about", description: "About" },
{ linkName: "/associations", description: "Associations" },
{ linkName: "/contact", description: "Contact" },
{ linkName: "/locations", description: "Scoutsurfing" }
]

export class Menu extends React.PureComponent {
    refMenuContainer;
    scrollPosition = 0;
    scrollUpMenuHeight = 0;
    menuMaxHeight;
    scrollAmount = 0;
    lastScrollPosition = 0;

    constructor(props) {
        super(props);
        this.state = { currentItem: "/" };
        this.toggleContainer = this.toggleContainer.bind(this);
         this.handleClickMenu = this.handleClickMenu.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.toggleContainer);

        this.menuMaxHeight = this.refMenuContainer.getBoundingClientRect().height;
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.toggleContainer);

    }



    toggleContainer() {
        if (this.scrollPosition - this.lastScrollPosition < -60 || this.scrollPosition - this.lastScrollPosition > 60) {
            if (document.body.getBoundingClientRect().top > this.scrollPosition) {

                // if (this.scrollAmount > 0) {
                //     this.scrollAmount = 0;
                // }
                // this.scrollAmount--;
                // if (this.scrollAmount === -2 ) {
                //     this.refMenuContainer.style.height=`${this.menuMaxHeight}px`;

                // }
                this.refMenuContainer.style.height = `${this.menuMaxHeight}px`;
            } else {
                // if (this.scrollAmount < 0) {
                //     this.scrollAmount = 0;
                // }
                // if ( this.scrollPosition < this.menuMaxHeight / 2) {
                //     this.scrollAmount++;
                // }
                // if (this.scrollAmount === 3 ) {
                //     this.refMenuContainer.style.height=`0px`;
                // }
                this.refMenuContainer.style.height = `0px`;
                this.scrollUpMenuHeight = 0;
            }
            this.lastScrollPosition = this.scrollPosition;
        }
        this.scrollPosition = (document.body.getBoundingClientRect()).top;

    }
    handleClickMenu(linkName) {
        this.setState({ currentItem: linkName });
        this.props.onSelectItem(linkName);
    }

    render() {
        let size = this.props.responsive === "desktop" ? "96px" : "58px";

        return (
            <div ref={c => this.refMenuContainer = c}
                className="header">

                <div className="bar-images">
                    <img src={Logo} width={size} height={size} />
                </div>
                <div className="text">
                    CONFÉDÉRATION EUROPÉENNE DE SCOUTISME
                </div>


                <div className="menu-bar">
                    {MenuItems.map((menuItem, index) =>
                        <a key={index}
                            onClick={() => this.handleClickMenu(menuItem.linkName)}
                            className={`menu-item${this.props.currentItem === menuItem.linkName ? " active" : ""}`}>{menuItem.description}</a>)}
                </div>
            </div>

        )
    }
}

export default Menu;


// {MenuItems.map((menuItem, index) => <Link key={index}
//                         to={menuItem.linkName}
//                         onClick={() => this.setState({ currentItem: menuItem.linkName })}
//                         className={`menu-item${this.state.currentItem === menuItem.linkName ? " active" : ""}`}>{menuItem.description}</Link>)}
