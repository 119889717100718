import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApplicationModel from './ApplicationModel';

export default class ShortcutItems extends React.PureComponent {

    constructor() {
        super();
        this.state = { events: [], currentEventIndex: 0 };
        this.mobileShortcuts=this.mobileShortcuts.bind(this);
        this.nextEvent=this.nextEvent.bind(this);
    }

    componentDidMount() {
        fetch(
            ApplicationModel.APIBASE + '/GetEvents.php', {
            method: 'GET'
        }).then(response => response.json()).
            then(result => this.setState({ events: result.events.filter(ev=>this.isFutureEvent(ev)) }));
        window.setTimeout(()=>this.nextEvent(),5000);
    }

isFutureEvent(cesEvent) {
    
    if (cesEvent.beginDate === null || cesEvent.beginDate === undefined) return true;
  const dtSplit = cesEvent.beginDate.split("-");
  const yr=dtSplit[0];
  const mnth=dtSplit[1];
  const day=dtSplit[2];
  const today = new Date();
  return today.getFullYear() <= parseInt(yr,10) && today.getMonth() <= parseInt(mnth - 1,10) && today.getDay() <= parseInt(day,10);
}

nextEvent() {
    const maxIndex = this.state.events.length - 1;
    let newEventIndex = this.state.currentEventIndex + 1;
    if (newEventIndex > maxIndex) newEventIndex = 0;
    this.setState({currentEventIndex:newEventIndex});
    window.setTimeout(()=>this.nextEvent(),5000);
}

    mobileShortcuts() {
        const currentEvent = this.state.events[this.state.currentEventIndex];
        let eventText = currentEvent !== undefined && currentEvent !== null ? currentEvent.beginDate + " : " + currentEvent.description
        : "No events...";
        return (
            <div className="shortcut-items" >
                <div className="item">
                    <span className="text">{eventText} </span>
                </div>
            </div>
        );
    }

    desktopShortcuts() {
        return (
            <div className="shortcut-items" >
                <div className="item">
                    <span className="text">Upcoming Events: </span>
                </div>
                <ul style={{ listStyle: "none", paddingLeft: "0px" }}>
                    {this.state.events && this.state.events.length > 0 ?
                        this.state.events.map(event =>
                            <li key={event.eventId} style={{ paddingBottom: "8px", paddingLeft: "0px", color: "#fff", fontWeight: "600" }}>
                                {event.beginDate} : {event.description}
                            </li>)
                        : <span className='text' style={{color:'#fff'}}>No events planned...</span>}
                </ul>
            </div>
        )
    }


    render() {
        return this.props.responsive === "desktop" ? this.desktopShortcuts() : this.mobileShortcuts();

    }
}
