import React from 'react';
import ApplicationModel from './Components/ApplicationModel';
import flgBE from './images/be.svg';
import flgNL from './images/nl.svg';
import flgDE from './images/de.svg';
import flgES from './images/es.svg';
import flgGB from './images/gb.svg';
import flgIT from './images/it.svg';
import flgLT from './images/lt.svg';
import flgPL from './images/pl.svg';
import flgBR from './images/br.svg';

export default class AssociationsComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { htmlContent: null };

    }
    associations = [
        {
            flag: flgBE,
            association: "member",
            country: "Belgium",
            organization: "Europe et Scoutisme",
            link: "http://www.groupedestilleuls.be/europe-et-scoutisme"
        },
        {
            flag: flgNL,
            association: "member",
            country: "The Netherlands",
            organization: "Federatie Scouting Europa Nederland",
            link: "http://www.fse-nederland.nl"
        },
        {
            flag: flgDE,
            association: "member",
            country: "Germany",
            organization: "Bund Europäischer Pfadfinder",
            link: "https://www.bep-online.de"
        },
        {
            flag: flgIT,
            association: "member",
            country: "Italy",
            organization: "Association of Italian Catholic Scouts - ASCI",
            link: null
        },
        {
            flag: flgGB,
            country: "United Kingdom",
            association: "member",
            organization: "European Scout Federation (British Association)",
            link: "https://www.fse-scouts.eu"
        },
        {
            flag: flgLT,
            country: "Lithuania",
            association: "member",
            organization: "Związek Harcerstwa Polskiego na Litwie",
            link: "https://zhpnl.lt"
        },
        {
            flag: flgES,
            country: "Spain",
            association: "friend",
            organization: "Organizacion Juvenile Espanola",
            link: "https://oje.es"
        },
        {
            flag: flgPL,
            country: "Poland",
            association: "friended",
            organization: "Związek Harcerstwa Rzeczypospolitej",
            link: "https:/www.zhr.pl"
        },
        {
            flag: flgBR,
            country: "Brasil",
            association: "corresponding",
            organization: "Associação Escoteira Independente Pirai (AESPI)",
            link: null
        }

    ]

    componentDidMount() {
        window.scrollTo(0, 1);
        fetch(ApplicationModel.HTMLBASE + 'associations_content.html').then(response => response.text())
            .then(result => {

                this.setState({ htmlContent: result })
            });

    }

    createCountryRow(association) {
        return (
            <tr class="country-row" key={association.country}>
                <td style={{ minWidth: '20px' }}><img style={{ width: "16px" }} src={association.flag} /></td>
                <td style={{ minWidth: '120px' }}>{association.country}</td>
                <td>:</td>
                <td>{association.link ? <a target="new" href={association.link}>{association.organization}</a>
                    : association.organization
                }</td>
            </tr>
        );
    }




    render() {
        return (
            <>
                <div className="page-content">
                    <h1>Associations</h1>
                    <h3>Member associations</h3>
                    <table>
                        {this.associations.filter(ass => ass.association === "member").map(ass => this.createCountryRow(ass))}
                    </table>
                    <h3>Friend association</h3>
                    <table>
                        {this.associations.filter(ass => ass.association === "friend").map(ass => this.createCountryRow(ass))}
                    </table>
                    <h3>Befriended associations</h3>
                    <table>
                        {this.associations.filter(ass => ass.association === "friended").map(ass => this.createCountryRow(ass))}
                    </table>
                    <h3>Corresponding member</h3>
                    <table>
                        {this.associations.filter(ass => ass.association === "corresponding").map(ass => this.createCountryRow(ass))}
                    </table>
                </div>
                {/* <div className="page-content" dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}>

                </div> */}

            </>
        );
    }
};