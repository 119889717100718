import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApplicationModel from './ApplicationModel';

export default class NewsItem extends React.PureComponent {

    constructor() {
        super();

    }




    render() {
        return (
        <React.Fragment>
<hr/>
<div style={{padding:"0 8px"}}>
<h3>{this.props.newsItemTitle}</h3>
<div dangerouslySetInnerHTML={{ __html: this.props.newsItemContent }} />
</div>
<br/>
</React.Fragment>
        );
    }
}
