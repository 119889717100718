import React from 'react';
import { TextField, Button } from '@material-ui/core';
import ApplicationModel from './Components/ApplicationModel';


export default class ContactComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = { contact: { firstname: null, surname: null , email_adress: null, phone: null, question:null },message:"",validated:false}
        this.handlePostForm = this.handlePostForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.formValidated = this.formValidated.bind(this);

    }


    componentDidMount() {
        window.scrollTo(0, 1);

    }

    handleChange(event) {
        let contact = this.state.contact;
        contact[event.target.id] = event.target.value;
        this.setState({ contact: contact});
        this.forceUpdate();
    }

    formValidated(contact) {
        
        let validated = true;
        if (contact.email_adress === "" || contact.email_adress === null) {

            validated = false;
        }
        if (contact.question === "" || contact.question === null) {

            validated = false;
        }
        console.log("validated "+ validated);
        return validated;
    }

    handlePostForm() {

        if (this.formValidated(this.state.contact)) {
            fetch(
                ApplicationModel.APIBASE + '/PostQuestion.php',
                {
                    method: "POST",
                    body: JSON.stringify(this.state.contact)
                })
                .then(response => response.json())
                .then(result => {console.log(result);
                if (result.status < 0) {
                    this.setState({message:"There was a problem sending your message"});
                } else {
                    this.setState({ contact: { firstname: null, surname: null, email_adress: null, phone: null, question: null },
                        message:"Your question has been sent" })
                }
            })
            .catch(err=> {
                this.setState({message:"There was a problem sending your message"});
            });
        }
    }

    render() {
        
        return (
            <div className="page-content" >
                <h1>Contact</h1>
                <div className="form-contact" >
                    <div>{this.state.message}</div>
                    <div className="form-section">
                        <h4>Contact details</h4>
                        <TextField id="firstname" onChange={this.handleChange} value={this.state.contact.firstname} label="First name" required={true} />
                        <TextField id="surname" onChange={this.handleChange} value={this.state.contact.surname ?? ""} label="Surname" required={true} />
                    </div>
                    <div className="form-section">
                        <TextField id="email_adress" onChange={this.handleChange} value={this.state.contact.email_adress} label="Emailadress" required={true} fullWidth={true} type="email" />
                        <TextField id="phone" onChange={this.handleChange} value={this.state.contact.phone} label="Phone number" />
                    </div>
                    <div className="form-section">
                        <TextField id="question" onChange={this.handleChange} value={this.state.contact.question} label="Your question" required={true} multiline={true} fullWidth={true} rows={5} variant="outlined" />

                    </div>
                    <Button disabled={!this.formValidated(this.state.contact)} onClick={this.handlePostForm} variant="contained" >Send</Button>
                    <div>{this.state.message}</div>
                </div>
            </div>

        );
    }
};