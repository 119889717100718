import React from 'react';
import Image1 from './images/himg1.png';
import Image2 from './images/himg2.png';
import Image3 from './images/himg3.png';
import ApplicationModel from './Components/ApplicationModel';
import NewsItem from './Components/newsItem';

export default class HomeComponent extends React.PureComponent {


    constructor(props) {
        super(props);
        this.state = { htmlContent: null, newsItems: [], archiveItems: [], showArchive:false };

    }

    componentDidMount() {
        fetch(ApplicationModel.HTMLBASE + 'home_content.html').then(response => response.text())
            .then(result => {

                this.setState({ htmlContent: result })
            });
        fetch(
            ApplicationModel.APIBASE + '/GetNewsItems.php')
            .then(response => response.json())
            .then(result => this.setState({ newsItems: result.newsItems.filter(i=>!this.isArchive(i)),
            archiveItems: result.newsItems.filter(i=>this.isArchive(i)) }));
        //     method: 'POST',

        //     body: JSON.stringify({ registration: "success", name: "RHAASTRE", email: "abc@gmail.com" })
        //  } ).then(response=>response.json()).
        // then(result=>this.setState({role:result.role}));
    }

    isArchive(item) {
    
        if (item.itemDate === null || item.itemDate === undefined) return true;
      const dtSplit = item.itemDate.split("-");
      const yr=dtSplit[0];
      const mnth=dtSplit[1];
      const day=dtSplit[2];
      const today = new Date();
      return ((today.getFullYear() >= parseInt(yr,10) + 1) && today.getMonth() >= parseInt(mnth - 1,10)) || (today.getFullYear() >= parseInt(yr,10) + 2);
    }

    render() {


        return (


            <div className="page-content" >

                <div className="home-image-wrapper">
                    <div className="home-image-list">
                        <img src={Image1} />
                        <img src={Image2} />
                        <img src={Image3} />
                    </div>
                </div>
                <h2>Welcome to the website of the CES!</h2>
                <br />
                <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />
                {this.state.newsItems.length > 0 ?
                    this.state.newsItems.map(item => <NewsItem newsItemTitle={item.itemTitle} newsItemContent={item.itemContent} />)

                    : null}
                {this.state.archiveItems.length > 0 ?
                        this.state.showArchive ?
                        this.state.archiveItems.map(item => <NewsItem newsItemTitle={item.itemTitle} newsItemContent={item.itemContent} />)
                        : <div style={{display:'flex',justifyContent:'center'}}>
                            <button style={{border:0, background:'#fff',textDecoration:'underline',cursor:'pointer' }} 
                            onClick={()=>this.setState({showArchive:true})}>Show older news items...</button>
                        </div>
                   : null
                }
            </div>


        );
    }
};