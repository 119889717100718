import React from 'react';
import Menu from './menu';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import HomeComponent from './Home';
import ShortcutItems from './Components/shortcuts';
import ContactComponent from './Contact';
import AboutComponent from './About';
import AssociationsComponent from './Associations';
import LocationsComponent from './Locations';
import FooterImage from './images/footerblue.png';


class App extends React.PureComponent {

  constructor() {
    super();
    this.state = { responsive: window.innerWidth > 700 ? "desktop" : "mobile",currentComponent:"/" };
    this.handleResize = this.handleResize.bind(this);
    this.handleSelectComponent=this.handleSelectComponent.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.setState({currentComponent:params.get('page')});
  }

  handleResize() {

    let currentResp = this.state.responsive;
    if (window.innerWidth < 700 && currentResp !== "mobile") {
      this.setState({ responsive: "mobile" });
    } else if (window.innerWidth >= 700 && currentResp !== "desktop") {
      this.setState({ responsive: "desktop" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleSelectComponent(linkName) {
    const url = new URL(window.location);
    url.searchParams.set('page', linkName);
    window.history.pushState({}, '', url);
    this.setState({currentComponent:linkName});
  }


  getRequestedComponent() {
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // params.get('page')
    switch (this.state.currentComponent) {
      case "/":
        return <HomeComponent />;
        break;
      case "/about":
        return <AboutComponent />;
        break;
      case "/associations":
        return <AssociationsComponent />;
        break;
      case "/contact":
        return <ContactComponent />;
        break;
      case "/locations":
        return <LocationsComponent />;
        break;
      default:
        return <HomeComponent />;
    };
  }

  render() {
    let hashHistory = Router.hashHistory;

    return (
      <React.Fragment>
        <Menu onSelectItem={this.handleSelectComponent} currentItem={this.state.currentComponent} responsive={this.state.responsive}/>
        <main>
          <div className="grid-container" >
            <div className="content">
              {this.getRequestedComponent()}
            </div>
            <div className="shortcuts"><ShortcutItems responsive={this.state.responsive}/></div>

          </div>
        </main>
        <footer>
          <img src={FooterImage}/>
        </footer>
      </React.Fragment>
    );


    // return (
    //   <React.Fragment>
    //     <Menu />
    //     <main>
    //       <div className="grid-container" >
    //         <div className="content">
    //           <Switch history={hashHistory} >
    //             <Route path="/" component={HomeComponent} exact />
    //             <Route path="/about" component={AboutComponent} />
    //             <Route path="/associations" component={AssociationsComponent} />
    //             <Route path="/contact" component={ContactComponent} />
    //             <Route path="/locations" component={LocationsComponent} />
    //           </Switch>
    //         </div>
    //         <div className="shortcuts"><ShortcutItems /></div>

    //       </div>
    //     </main>

    //   </React.Fragment>
    // );
  }
}
export default App;
//<div className="sponsors"><Carousel/></div>