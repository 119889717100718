import React from 'react';


export default class ApplicationModel  {

   //static APIBASE = "http://localhost:8080/api";
    // static HTMLBASE = "/";
    // static MAPSKEY = "AIzaSyCG7CFJzdHTc0WtWb8DnrZjlgT91KOVHCE";
    
    // server:
    static APIBASE = "./api";
    //static APIBASE = "https://ces-scout.eu/api"
    static HTMLBASE = "/";
    static MAPSKEY = "AIzaSyBiLQYZUgywtSCsPPStwZT5gfNMeKVEMis";
    //static MAPSKEY = "BIzaSyBiLQYZUgywtSCsPPStwZT5gfNMeKVEMit";
};